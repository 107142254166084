/* Make the toolbar wider */
.video-js .vjs-control-bar {
    height: 50px; /* Increase height of the control bar */
    background-color: #333; /* Optional: Change background color */
  }
  
  /* Style the play/pause button */
  .video-js .vjs-play-control {
    color: #fff; /* Change text color to white */
  }
  
  .video-js .vjs-play-control .vjs-icon-placeholder:before {
    color: #ff0000; /* Change play button color */
  }
  
  .video-js .vjs-play-control.vjs-playing .vjs-icon-placeholder:before {
    color: #00ff00; /* Change pause button color */
  }
  
  /* Example: Change the color of the volume button */
  .video-js .vjs-volume-panel .vjs-volume-level {
    background-color: #00f; /* Change volume level color */
  }
  