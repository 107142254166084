.nav {
    position: absolute;
    top: 0;
    width: 100%;
    display: flex;
    background: rgba(0, 0, 0, 0.2);
    z-index: 10;
    padding: 10px 0;
    justify-content: center;
  }
  
  .nav .ul {
    list-style: none;
    display: flex;
    gap: 20px;
  }

  .nav-options {
    margin: 2%;
  }
  
  .nav .ul .li .a {
    text-decoration: none;
    color: #fff;
    font-weight: bold;
  }
  
  .nav .ul .li .a:hover {
    text-decoration: underline;
  }
  
  .nav .ul .li .a.active {
    color: #f5c518;
  }
  
  /* .nav ul li a.active::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: #f5c518;
    bottom: -5px;
    left: 0;
  } */
  
  .nav .ul .li .a.glow {
    text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #f5c518, 0 0 20px #f5c518, 0 0 25px #f5c518, 0 0 30px #f5c518, 0 0 35px #f5c518;
  }